import React, { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../../components";
import { items } from "../../../data/products";
import useLocalStorage from "../../../hooks/useLocalStorage";
import "./ProductDetail.scss";

interface ProductDetailViewInterface {
  product: any;
}

const ProductDetail: React.FC = () => {
  const params = useParams();
  const itemToBeDisplayed = items.find(
    (value) => value.id === Number(params.id)
  );
  return itemToBeDisplayed !== undefined ? (
    <ProductDetailView product={itemToBeDisplayed} />
  ) : (
    <ProductDetailError />
  );
};

const ProductDetailView: React.FC<ProductDetailViewInterface> = ({
  product,
}) => {
  const [productsQuoted, setProductsQuoted] = useLocalStorage(
    "productsQuoted",
    [] as any[]
  );
  const [units, setUnits] = useState(() => {
    if (productsQuoted.length !== 0) {
      return productsQuoted.find((value) => value.product.id === product.id)
        ? (productsQuoted.find((value) => value.product.id === product.id)
            .units as number)
        : 0;
    } else {
      return 0;
    }
  });

  const analyticBackTracking = () => {
    window.gtag("event", "back_to_products", {});
  };
  const onAddToQuoteBtn = useCallback(() => {
    if (units === 0) {
      alert(`Cannot add 0 ${product.unitName} to quotation.`);
    } else {
      try {
        let newProductsQuoted = [];
        let productsIndex = productsQuoted.findIndex(val => val.product.id === product.id);
        if(productsIndex === -1) {
           newProductsQuoted = productsQuoted.concat({
              units: units,
              product: product,
          });
        } else {
          productsQuoted[productsIndex].units += units;
          newProductsQuoted = productsQuoted;
        }
        setProductsQuoted(newProductsQuoted);
        window.gtag("event", "add_to_quote", {
          page: "product_detail",
          productName: product.name,
          quantity: units,
        });
        alert("Added to quotation successfuly!");
      } catch (error) {
        alert("ERROR: add to quotation!");
      }
    }
  }, [productsQuoted, setProductsQuoted, product, units]);
  return (
      <div className="productsDetailContainer">
          <Link to={"/products"} color="#135040" className="navigation">
              <div>
                  <p onClick={analyticBackTracking}>
                      <span>{"<"}</span> Kembali ke Halaman Produk
                  </p>
              </div>
          </Link>
          <div className="productsDetailSection">
              <div className="productsDetailSection__image">
                  <img src={product.image} alt={`product-${product.id}`} />
              </div>
              <div className="productsDetailSection__specs">
                  <p className="productsDetailSection__specs__title">
                      {product.name}
                  </p>
                  <br />
                  <p className="productsDetailSection__specs__subtitle">
                      Kuantitas
                  </p>
                  <p>
                      Per{" "}
                      {product.unitName +
                          " (" +
                          product.amountMultiplier +
                          " pcs)"}
                  </p>
                  <br />
                  <div className="productsDetailSection__specs__quotation">
                      <div className="productsDetailSection__specs__quotation__qtySection">
                          <button
                              className="qtyBtn"
                              onClick={() =>
                                  units > 0 && setUnits((units) => units - 1)
                              }
                          >
                              -
                          </button>
                          <input
                              className="productsDetailSection__specs__quotation__qtySection__units"
                              type="number"
                              value={units}
                              onChange={(e) => {
                                setUnits(Number(e.target.value))
                              }}
                          />
                          <button
                              className="qtyBtn"
                              onClick={() => setUnits((units) => units + 1)}
                          >
                              +
                          </button>
                      </div>
                      <button
                          onClick={() => onAddToQuoteBtn()}
                          className="quoteBtn"
                      >
                          Masukan Produk
                      </button>
                  </div>
                  <br />
                  <br />
                  <p className="productsDetailSection__specs__subtitle">
                      Spesifikasi
                  </p>
                  {product.specification.map((value: any, idx: number) => {
                      return (
                          <>
                              <div
                                  className="keyValue"
                                  style={{
                                      borderBottom:
                                          idx + 1 ===
                                          Number(product.specification.length)
                                              ? "2px solid #e7e7e7"
                                              : "",
                                  }}
                              >
                                  <p>
                                      {value["key"] === ""
                                          ? "Key Kosong"
                                          : value["key"]}
                                  </p>
                                  <p>{value["value"]}</p>
                              </div>
                          </>
                      );
                  })}
              </div>
          </div>
      </div>
  );
};

const ProductDetailError: React.FC = () => {
  return (
    <div className="">
      <h3>Produk tidak ditemukan!</h3>
      <p>
        Sepertinya produk yang kamu cari tidak ada. Kamu bisa cari produk kami yang lain yang bisa memenuhi kebutuhanmu!
      </p>
      <Button>Jelajahi produk lain</Button>
    </div>
  );
};

export default ProductDetail;
