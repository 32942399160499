import React, { useState } from "react";
import { FaqArrowIcon } from "../../../assets";
import { faqMap } from "../../../data/contactUs";
import "./Faq.scss";

interface FaqItemProps {
    q: string;
    a: string;
}
const FaqItem: React.FC<FaqItemProps> = ({ q, a }) => {
    const [showItem, setShowItem] = useState(false);
    return (
        <div className="faqItemContainer">
            <div
                className="faqItemContainer__upper"
                onClick={() => setShowItem((showItem) => !showItem)}
            >
                <p className="faqItemContainer__upper__q">{q}</p>
                <img
                    className="faqItemContainer__upper__arrow"
                    style={{
                        transform: showItem
                            ? "rotate(180deg)"
                            : "rotate(90deg)",
                    }}
                    src={FaqArrowIcon}
                    alt="arrow"
                />
            </div>
            <p
                className="faqItemContainer__lower"
                style={showItem ? {} : { height: "0px", margin: "0px" }}
            >
                {a}
            </p>
        </div>
    );
};

const Faq: React.FC = () => {
    return (
        <div className="faqContainer">
            <p className="faqTitle">Halo, adakah yang bisa kami bantu?</p>
            <div className="faqItems">
                {faqMap.map((val) => {
                    return (
                        <div>
                            <FaqItem q={val.q} a={val.a} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Faq;
