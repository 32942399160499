import React, { useState } from 'react'
import { FaqImage, InstagramIcon, PhoneIcon } from '../../assets';
import { mapsUrlBranch, mapsUrlBranchNoIframe, mapsUrlMain, mapsUrlMainNoIframe } from '../../data/contactUs';
import "./ContactUs.scss";
import Faq from './Faq';

const ContactUs: React.FC = () => {
    const [buttonChosen, setButtonChosen] = useState(true);
    return (
        <div className="contactUsContainer">
            <div id="contact-info" className="contactUsContainer__contactInfo">
                <p className="contactUsContainer__contactInfo__title">
                    Informasi Kontak
                </p>
                <div className="contactUsContainer__contactInfo__buttons">
                    <button
                        className={
                            buttonChosen
                                ? "contactUsButtonActive"
                                : "contactUsButton"
                        }
                        onClick={() =>
                            !buttonChosen &&
                            setButtonChosen((buttonChosen) => !buttonChosen)
                        }
                    >
                        Kantor Pusat
                    </button>
                    <button
                        className={
                            buttonChosen
                                ? "contactUsButton"
                                : "contactUsButtonActive"
                        }
                        onClick={() =>
                            buttonChosen &&
                            setButtonChosen((buttonChosen) => !buttonChosen)
                        }
                    >
                        Kantor Cabang
                    </button>
                </div>
                <hr className="divider" />
                <iframe
                    title="maps"
                    className="contactUsContainer__contactInfo__image"
                    loading="lazy"
                    src={buttonChosen ? mapsUrlMain : mapsUrlBranch}
                />
                <div className="contactUsContainer__contactInfo__addressSection">
                    <div className="contactUsContainer__contactInfo__addressSection__address">
                        <p className="contactUsContainer__contactInfo__addressSection__address__address">
                            {buttonChosen
                                ? "Jl. Jend. Gatot Subroto Km. 4, Kawasan Industri Kalisabi No. 7 Cibodas Tangerang, Banten 15138, Indonesia"
                                : "Jl. Wringinanom Km. 30, 7 Desa Sumengko Kec. Wringinanom Gresik, Jawa Timur 61176, Indonesia"}
                        </p>
                        <a
                            className="contactUsContainer__contactInfo__addressSection__address__hyperlink"
                            rel="noreferrer"
                            target="_blank"
                            href={buttonChosen ? mapsUrlMainNoIframe : mapsUrlBranchNoIframe}
                        >
                            Tampilkan pada Google Map
                        </a>
                    </div>
                    <p className="contactUsContainer__contactInfo__addressSection__contact">
                        {" "}
                        <span>
                            <img src={PhoneIcon} alt="" />
                        </span>
                        {buttonChosen
                            ? "(62-21) 5577 3477"
                            : "(62-31) 8983 677"}
                    </p>
                </div>
                <a
                    className="contactUsContainer__contactInfo__instagram"
                    target="_blank"
                    href="https://www.instagram.com/gosyenpacific/?hl=id"
                    rel="noreferrer"
                >
                    <span>
                        <img src={InstagramIcon} alt="" />
                    </span>
                    Follow Akun Instagram Kami
                </a>
            </div>
            <div id="faq" className="contactUsContainer__faq">
                <div
                    className="contactUsContainer__faq__banner"
                    style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${FaqImage})`,
                    }}
                >
                    <p className="contactUsContainer__faq__banner__title">
                        FAQ
                    </p>
                </div>
                <Faq />
            </div>
        </div>
    );
}

export default ContactUs;
