import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NextArrow, PrevArrow } from "../../../assets";
import "./Gallery.scss";

const Gallery: React.FC = () => {
  return (
    <div className="gallery" id="gallery">
      <Carousel
        className="gallery__container"
        infiniteLoop={true}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        renderArrowPrev={(e) => {
          return (
            <div onClick={e}>
              <img src={PrevArrow} alt="prev-arrow" className="arrow" />
            </div>
          );
        }}
        renderArrowNext={(e) => {
          return (
            <div onClick={e}>
              <img src={NextArrow} alt="next-arrow" className="arrow" />
            </div>
          );
        }}
      >
        <div>
          <img
            src="https://ik.imagekit.io/chronoarc/Gallery-1_Ijv4PHMZF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658936623557"
            alt="gallery"
          />
        </div>
        <div>
          <img
            src="https://ik.imagekit.io/chronoarc/Gallery-2_MhQE2OYxI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658937048867"
            alt="gallery"
          />
        </div>
        <div>
          <img
            src="https://ik.imagekit.io/chronoarc/4-1_OIpQeKUbT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659091833017"
            alt="gallery"
          />
        </div>
        <div>
          <img
            src="https://ik.imagekit.io/chronoarc/5-2_ara5qEMv7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659091830938"
            alt="gallery"
          />
        </div>
        <div>
          <img
            src="https://ik.imagekit.io/chronoarc/7-1_u-6Wpvy9B.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659091831016"
            alt="gallery"
          />
        </div>
        <div>
          <img
            src="https://ik.imagekit.io/chronoarc/Gallery-2_MhQE2OYxI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658937048867"
            alt="gallery"
          />
        </div>
      </Carousel>
    </div>
  );
};

export default Gallery;
