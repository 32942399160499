import React, { useCallback, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import Button from "../Button";
import "./QuotationModal.scss";
import QuotationModalItem from "./QuotationModalItem";

interface QuotationModalProps {
  isShow: boolean;
  onClose: () => void;
  ref?: React.MutableRefObject<any>;
}

function useOutsideAlerter(ref: React.MutableRefObject<any>, onClose: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, onClose]);
}


const QuotationModal: React.FC<QuotationModalProps> = ({
  isShow,
  onClose,
}) => {
  const divRef = useRef(null);
  const [productsQuoted, setProductsQuoted] = useLocalStorage(
    "productsQuoted",
    [] as any[]
  );
  useOutsideAlerter(divRef, onClose);
  const navigate = useNavigate();
  const onAddBtnClick = useCallback(
    (prodId: number) => {
      let currProd = productsQuoted.findIndex((val) => val.product.id === prodId);
      productsQuoted[currProd].units += 1;
      setProductsQuoted(productsQuoted);
    },
    [productsQuoted, setProductsQuoted]
  );
  const onDecBtnClick = useCallback(
    (prodId: number) => {
      let currProd = productsQuoted.findIndex((val) => val.product.id === prodId);
      productsQuoted[currProd].units -= 1;
      setProductsQuoted(productsQuoted);
    },
    [productsQuoted, setProductsQuoted]
  );
  const onRemoveBtnClick = useCallback(
    (prodId: number) => {
      let newProductsQuoted = productsQuoted.filter(
        (val) => val.product.id !== prodId
      );
      setProductsQuoted(newProductsQuoted);
    },
    [productsQuoted, setProductsQuoted]
  );
  const onUnitsChange = useCallback((prodId: number, qty: number) => {
    let currProd = productsQuoted.findIndex((val) => val.product.id === prodId);
    productsQuoted[currProd].units = qty;
    setProductsQuoted(productsQuoted);
  }, [productsQuoted, setProductsQuoted])

  const exploreOurProductTracking = () => {
    window.gtag("event", "explore_products", {
      page: "RFQ",
    });
  };

  const requestForQuotationTracking = () => {
    window.gtag("event", "request_quotation", {
      total_products: productsQuoted.length,
    });
  };
  return isShow ? (
    <div
      ref={divRef}
      className={
        isShow ? "quotationModalContainer" : "quotationModalContainerInactive"
      }
    >
      <div className="quotationModalHeader">
        <p className="quotationModalHeader__title">
          Request for quotation{" "}
          <span>
            {productsQuoted.length !== 0 ? `(${productsQuoted.length})` : null}
          </span>
        </p>
        <button
          onClick={() => (onClose !== undefined ? onClose() : () => { })}
          className="quotationModalHeader__closeBtn"
        >
          ✕
        </button>
      </div>
      <div className="quotationModalBody">
        {productsQuoted.length === 0 ? (
          <div>
            <p>Your quotation is empty</p>
          </div>
        ) : (
          productsQuoted.map((val) => {
            return (
              <QuotationModalItem
                onAddBtnClick={onAddBtnClick}
                onDecBtnClick={onDecBtnClick}
                onRemoveBtnClick={onRemoveBtnClick}
                onUnitsChange={onUnitsChange}
                product={val}
              />
            );
          })
        )}
      </div>
      {productsQuoted.length === 0 ? (
        <div className="quotationModalFooter">
          <Button
            onClick={() => {
              navigate("/products");
              onClose();
              exploreOurProductTracking();
            }}
          >
            Jelajahi Produk
          </Button>
        </div>
      ) : (
        <div className="quotationModalFooter">
          <Link to="/quotation">
            <Button
              color="#135040"
              onClick={() => {
                onClose();
                requestForQuotationTracking();
              }}
            >
              Request for Quotation
            </Button>
          </Link>
        </div>
      )}
    </div>
  ) : (
    <div className={"quotationModalContainerInactive"}></div>
  );
};

export default QuotationModal;
