export const uvp = [
    {
        title: "Praktis dan ekonomis",
        text: "Kami akan selalu adaptif terhadap perubahan gaya hidup masyarakat dengan memberikan solusi produk yang praktis, ekonomis dan mempunyai nilai estetika",
    },
    {
        title: "Ramah lingkungan",
        text: "Kami selalu memastikan penggunaan material produksi yang bisa didaur ulang dan menggunakan bahan daur ulang itu sendiri dalam proses produksinya",
    },
    {
        title: "Tersertifikasi SNI",
        text: "Semua produk yang kami produksi sudah terbukti kualitas dan keamanan nya sesuai standar SNI",
    },
];
