import React from "react";
import { Link } from "react-router-dom";
import { DownArrow } from "../../assets";
import "./Sidebar.scss";

interface Status {
  product: boolean;
  contactus: boolean;
  aboutus: boolean;
}
interface Props {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<any>>;
  product: {
    name: string;
    route: string;
  }[];
  about: {
    name: string;
    route: string;
  }[];
  contact: {
    name: string;
    route: string;
  }[];
  navHiddenStatus: Status;
  setNavHiddenStatus: React.Dispatch<React.SetStateAction<any>>;
  onRfqClick?: () => void;
}

interface PropsCards {
  menus: {
    name: string;
    route: string;
  }[];
  parentRoute: string;
  hidden?: boolean;
  onClick?: () => void;
}

const SideCards: React.FC<PropsCards> = ({
  hidden,
  menus,
  parentRoute,
  onClick,
}) => {
  return (
    <>
      {hidden ? (
        <div className="sidecard sidecard-hidden">
          {menus.map((menu) => {
            return (
              <>
                <Link
                  to={`/${parentRoute}/${menu.route}`}
                  className="sidecard__menu"
                >
                  {menu.name}
                </Link>
              </>
            );
          })}
        </div>
      ) : (
        <div className="sidecard">
          {menus.map((menu) => {
            return (
              <React.Fragment key={menu.name}>
                {parentRoute === "products" ? (
                  <>
                    {menu.route === "all-product" ? (
                      <Link
                        to={`/${parentRoute}`}
                        className="sidecard__menu"
                        onClick={onClick}
                      >
                        {menu.name}
                      </Link>
                    ) : (
                      <Link
                        to={`/${parentRoute}?name=${menu.route}`}
                        className="sidecard__menu"
                        onClick={onClick}
                      >
                        {menu.name}
                      </Link>
                    )}
                  </>
                ) : (
                  <Link
                    to={`/${parentRoute}#${menu.route}`}
                    className="sidecard__menu"
                    onClick={onClick}
                  >
                    {menu.name}
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

const Sidebar: React.FC<Props> = ({
  setActive,
  active,
  product,
  about,
  contact,
  navHiddenStatus,
  setNavHiddenStatus,
  onRfqClick,
}) => {
  // console.log(active);
  return (
    <>
      {active ? (
        <div className="sidebar active">
          <div className="sidebar__items">
            <div
              className="sidebar__items__item"
              onClick={() =>
                setNavHiddenStatus({
                  ...navHiddenStatus,
                  product: !navHiddenStatus.product,
                  aboutus: true,
                  contactus: true,
                })
              }
            >
              <div>
                <p>Produk</p>
                <img src={DownArrow} alt="down-arrow" />
              </div>
              <SideCards
                menus={product}
                parentRoute="products"
                hidden={navHiddenStatus.product}
                onClick={() => {
                  setNavHiddenStatus({
                    ...navHiddenStatus,
                    product: !navHiddenStatus.product,
                    aboutus: true,
                    contactus: true,
                  });
                  setActive(!active);
                }}
              />
            </div>
            <div
              className="sidebar__items__item"
              onClick={() =>
                setNavHiddenStatus({
                  ...navHiddenStatus,
                  contactus: !navHiddenStatus.contactus,
                  aboutus: true,
                  product: true,
                })
              }
            >
              <div>
                <p>Hubungi Kami</p>
                <img src={DownArrow} alt="down-arrow" />
              </div>
              <SideCards
                menus={contact}
                parentRoute="contactus"
                hidden={navHiddenStatus.contactus}
                onClick={() => {
                  setNavHiddenStatus({
                    ...navHiddenStatus,
                    contactus: !navHiddenStatus.contactus,
                    aboutus: true,
                    product: true,
                  });
                  setActive(!active);
                }}
              />
            </div>
            <div
              className="sidebar__items__item"
              onClick={() =>
                setNavHiddenStatus({
                  ...navHiddenStatus,
                  aboutus: !navHiddenStatus.aboutus,
                  product: true,
                  contactus: true,
                })
              }
            >
              <div>
                <p>Tentang Kami</p>
                <img src={DownArrow} alt="down-arrow" />
              </div>
              <SideCards
                menus={about}
                parentRoute="aboutus"
                hidden={navHiddenStatus.aboutus}
                onClick={() => {
                  setNavHiddenStatus({
                    ...navHiddenStatus,
                    aboutus: !navHiddenStatus.aboutus,
                    product: true,
                    contactus: true,
                  });
                  setActive(!active);
                }}
              />
            </div>
            <div className="sidebar__items__item" 
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onRfqClick !== undefined ? onRfqClick() : () => {};
                    setActive(!active);
                  }}>
              <div>
                <p>Request for Quotation</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="sidebar">
          <div className="sidebar__items">
            <div className="sidebar__items__item">
              <div>
                <p>Produk</p>
                <img src={DownArrow} alt="down-arrow" />
              </div>
            </div>
            <div className="sidebar__items__item">
              <div>
                <p>Tentang Kami</p>
                <img src={DownArrow} alt="down-arrow" />
              </div>
            </div>
            <div className="sidebar__items__item">
              <div>
                <p>Hubungi Kami</p>
                <img src={DownArrow} alt="down-arrow" />
              </div>
            </div>
            <div className="sidebar__items__item">
              <div>
                <p>Request for Quotation</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
