import React from "react";
import "./Button.scss";
interface Props {
  children?: React.ReactNode;
  flag?: string;
  color?: string;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({ children, flag, color, onClick }) => {
  return (
    <>
      <button className={`btn ${color}`} onClick={() => onClick !== undefined ? onClick() : () => {}}>
        {flag ? <span>{flag}</span> : null}
        {children}
      </button>
    </>
  );
};

export default Button;
