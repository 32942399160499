import * as React from "react";
import { MedalLogo, PlasticsLogo, ReusableLogo } from "../../assets";
import { uvp } from "../../data/uvp";
import "./UVP.scss";

interface Props {}
const UVP: React.FC<Props> = (props: Props) => {
  return (
    <div className="uvpContainer">
      <div className="uvpList">
        <img
          className="uvpList__image"
          src={PlasticsLogo}
          alt="plastics-logo"
        />
        <p className="uvpList__title">{uvp[0].title}</p>
        <p className="uvpList__text">{uvp[0].text}</p>
      </div>
      <div className="uvpList">
        <img
          className="uvpList__image"
          src={ReusableLogo}
          alt="reusable-logo"
        />
        <p className="uvpList__title">{uvp[1].title}</p>
        <p className="uvpList__text">{uvp[1].text}</p>
      </div>
      <div className="uvpList">
        <img className="uvpList__image" src={MedalLogo} alt="reusable-logo" />
        <p className="uvpList__title">{uvp[2].title}</p>
        <p className="uvpList__text">{uvp[2].text}</p>
      </div>
    </div>
  );
};

export default UVP;
