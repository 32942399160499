export const ourStory = {
    title: "Pengalaman kami dengan kemasan berskala industri membantu kami mengidentifikasi solusi terbaik untuk memenuhi kebutuhan pelanggan kami.",
    description_1: `Sebagai bagian dari Gosyen Group Company, PT. Gosyen Pacific Sukses Makmur berdiri pada
                    pertengahan tahun 2002 dan mulai secara resmi beroperasi pada bulan Januari 2003 dengan
                    berlokasi di Kota Tangerang. Fokus bidang usaha kami adalah produksi kemasan makanan
                    berbahan dasar plastik.`,
    description_2: `Dengan berubahnya trend gaya hidup masyarakat yang semakin hari menjadi semakin cepat dan
                    instant, kami menyadari bahwa kebutuhan akan kemasan makanan dari plastik siap pakai akan
                    meningkat dari waktu ke waktu. Hal ini mendorong kami untuk memberikan solusi produk yang
                    praktis, ekonomis dan mempunyai nilai estetika untuk mengangkat nilai dari makanan yang
                    memakai produk kemasan kami.`,
    description_3: `Akibat dari semakin meningkatnya animo dan kebutuhan masyarakat atas produk kami, kami
                    mendirikan pabrik kedua di daerah Gresik-Jawa Timur untuk menjangkau pasar Indonesia Timur.
                    Kami pun telah bekerja sama dengan banyak perusahaan fast food ternama guna menyediakan
                    solusi kemasan makanan yang exclusive sesuai kebutuhan. Produk kami adalah food-grade dan
                    sudah mendapat lisensi Standar Nasional Indonesia (SNI).
                    `
}

export const visMis = {
    vision: "Menjadi perusahaan industri kemasan makanan berskala internasional yang menyediakan produk dan solusi kemasan makanan terbaik dalam rangka mengoptimalisasi kepuasan pelanggan.",
    mission: ["1. Menghasilkan produk kemasan makanan yang praktis dan ekonomis untuk menunjang kebutuhan pelanggan",
              "2. Pengembangan teknologi dan inovasi yang berkesinambungan untuk memberikan solusi produk kemasan makanan yang dapat memberikan nilai tambah terhadap isi dari produk kemasan makanan",
              "3. Membangun kemitraan jangka panjang dengan pelanggan berdasarkan asas saling percaya dan menguntungkan",
             ]
}
