import React from "react";
import { Link } from "react-router-dom";
import "./NavCards.scss";

interface Props {
  menus: {
    name: string;
    route: string;
  }[];
  parentRoute: string;
  hidden?: boolean;
  onClick?: () => void;
}

const NavCards: React.FC<Props> = ({ menus, parentRoute, hidden, onClick }) => {
  const onClickProductCard = (productName: string) => {
    window.gtag("event", "click_products_navbar", {
      category: productName,
    });
  };
  return (
    <>
      {hidden ? (
        <div className="nav-card hidden"></div>
      ) : (
        <ul className="nav-card">
          {menus.map((menu) => {
            return (
              <React.Fragment key={menu.name}>
                {parentRoute === "products" ? (
                  <>
                    {menu.route === "all-product" ? (
                      <Link
                        to={`/${parentRoute}`}
                        className="nav-card__menu"
                        onClick={() => {
                          onClick?.();
                          onClickProductCard(menu.name);
                        }}
                      >
                        <li>{menu.name}</li>
                      </Link>
                    ) : (
                      <Link
                        to={`/${parentRoute}?name=${menu.route}`}
                        className="nav-card__menu"
                        onClick={onClick}
                      >
                        <li>{menu.name}</li>
                      </Link>
                    )}
                  </>
                ) : (
                  <Link
                    to={`/${parentRoute}#${menu.route}`}
                    className="nav-card__menu"
                    onClick={onClick}
                  >
                    <li>{menu.name}</li>
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default NavCards;
