import * as React from "react";
import { useMediaQuery } from "react-responsive";
import {
  BkbLogo,
  ChatimeLogo,
  KopkenLogo,
  HokbenLogo,
  McdLogo,
} from "../../assets";
import "./ClientList.scss";

interface Props {}

const ClientList: React.FC<Props> = (props: Props) => {
  const mobile = useMediaQuery({
    query: "(max-width: 640px)",
  });
  return (
    <div className="clientListContainer">
      <div className="clientListTitle">
        <p className="clientListTitle__title">Our Partners</p>
        <p className="clientListTitle__text">
          We partner with distributors all over the world to innovate, produce,
          and deliver the best-in-class food packaging products for some of the
          biggest names in the industry.
        </p>
      </div>
      <div
        className="clientListLogo"
        style={{ flexDirection: mobile ? "column" : "row" }}
      >
        <div className="clientListLogo__item">
          <img src={KopkenLogo} alt="" />
        </div>
        <div className="clientListLogo__item">
          <img src={HokbenLogo} alt="" />
        </div>
        <div className="clientListLogo__item">
          <img src={McdLogo} alt="" />
        </div>
        <div className="clientListLogo__item">
          <img src={ChatimeLogo} alt="" />
        </div>
        <div className="clientListLogo__item">
          <img src={BkbLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ClientList;
