export const mapsUrlMain = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3393.212628865738!2d106.59613121430993!3d-6.19820266244575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69feeebcb1e3ff%3A0x3c14d00be0bf7348!2sPT.+Gosyen+Pacific+Suksesmakmur!5e1!3m2!1sid!2sid!4v1549591119191&amp;wmode=transparent";

export const mapsUrlBranch = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d920.253232371974!2d112.56174317560613!3d-7.389086843180918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e780859b29edeaf%3A0x763689e00886a522!2sGosyen+Pacific+Suksesmakmur!5e1!3m2!1sid!2sid!4v1549590696856&amp;wmode=transparent"

export const mapsUrlMainNoIframe = "https://www.google.com/maps/place/PT.+Gosyen+Pacific+Suksesmakmur,+Jl.+Jend.+Gatot+Subroto+Km.+4+Kawasan+Industri,+Jl.+Kalisabi+No.7,+RT.003%2FRW.012,+Uwung+Jaya,+Kec.+Cibodas,+Kota+Tangerang,+Banten+15138/@-6.1981739,106.5982567,17z/data=!4m5!3m4!1s0x2e69feeebcb1e3ff:0x3c14d00be0bf7348!8m2!3d-6.1981739!4d106.5982567?hl=id"

export const mapsUrlBranchNoIframe = "https://www.google.com/maps/place/PT.+Gosyen+Pacific+Suksesmakmur,+JL.+Wringinanom,+Km.+30,+No.+7,+Sumengko,+Wringinanom,+61176,+Sidomoro,+Pasinan+Lemahputih,+Kec.+Wringinanom,+Kabupaten+Gresik,+Jawa+Timur+61176/@-7.3892411,112.5613181,17z/data=!4m8!1m2!2m1!1sJl.+Wringinanom+Km.+30,+7+Desa+Sumengko+Kec.+Wringinanom+Gresik,+Jawa+Timur+61176,+Indonesia!3m4!1s0x2e780859b29edeaf:0x763689e00886a522!8m2!3d-7.3892411!4d112.5613181";

export const faqMap = [
    {
        q: "Bagaimana cara pemesanan barang yang tertera di website?",
        a: "Kamu dapat masuk ke halaman produk dan langsung memilih produk yang kamu inginkan. Setelah itu, klik tombol Request For Quotation. Kamu akan diarahkan untuk mengisi data kamu sebelum kami arahkan kamu untuk membuka halaman Whatsapp yang langsung terhubung ke nomor customer service Gosyen. Kemudian, customer service Gosyen akan membantu kamu untuk memproses pemesanan yang kamu buat."
    },
    {
        q: "Apakah saya bisa memesan lebih dari 1 barang?",
        a: "Kamu bisa langsung membeli 1 jenis barang dengan kuantitas yang berbeda - beda langsung melalui website."
    },
];

export const gosyenLatLon = [-6.198032410607944, 106.59829983290257];
