import React from "react";
import ClientList from "../../components/ClientsList";
import HeadlineCarousel from "./HeadlineCarousel";
import UVP from "../../components/UVP";
import ProductRange from "../../components/ProductRange";
import TagManager from "react-gtm-module";
// import Project from "./Project";
import OrderFlow from "../../components/OrderFlow";

const Home: React.FC = () => {
  const tagManagerArgs = {
    dataLayer: {
      event: "pageview",
      page: "home",
    },
    dataLayerName: "PageDataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);

  return (
    <div className="home">
      <HeadlineCarousel />
      <UVP />
      {/* <Project /> */}
      <ProductRange />
      <ClientList />
      <OrderFlow />
    </div>
  );
};

export default Home;
