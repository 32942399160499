import React, { useCallback } from "react";
import { TrashIcon } from "../../assets";
import "./QuotationSummary.scss";

interface Props {
  productsQuoted: any;
  setProductsQuoted: React.Dispatch<React.SetStateAction<any>>;
}

const QuotationSummary: React.FC<Props> = ({
  productsQuoted,
  setProductsQuoted,
}) => {
  function toPascalCase(text: string) {
    return text.replace(/(^\w|-\w)/g, clearAndUpper);
  }

  function clearAndUpper(text: string) {
    return text.replace(/-/, "").toUpperCase();
  }
  const onRemoveBtnClick = useCallback(
    (prodId: number) => {
      let newProductsQuoted = productsQuoted.filter(
        (val: any) => val.product.id !== prodId
      );
      setProductsQuoted(newProductsQuoted);
    },
    [productsQuoted, setProductsQuoted]
  );

  return (
    <div className="quotation-summary">
      {productsQuoted.length !== 0 ? (
        <div className="quotation-summary__container">
          <div className="quotation-summary__container__header">
            <h3>Product & Quantity ({productsQuoted.length})</h3>
            <hr />
          </div>
          <div className="quotation-summary__container__content">
            {productsQuoted.map((item: any) => {
              return (
                <div className="quotation-summary__container__content__item">
                  <div>
                    <img src={item.product.image} alt="item" />
                  </div>
                  <div className="quotation-summary__container__content__item__description">
                    <div>
                      <h3>{item.product.name}</h3>
                      <p>{toPascalCase(item.product.category)}</p>
                      <h3>
                        {item.units} {item.product.unitName.toLowerCase()}
                      </h3>
                    </div>
                    <button
                      onClick={() =>
                        onRemoveBtnClick !== undefined
                          ? onRemoveBtnClick(item.product.id)
                          : () => {}
                      }
                    >
                      <img src={TrashIcon} alt="x" />
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <h1>No Items Selected</h1>
      )}
    </div>
  );
};

export default QuotationSummary;
