import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ListProducts.scss";
import { products } from "../../../data/products";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
interface TagStatus {
  allProduct: boolean;
  cup: boolean;
  psFoam: boolean;
  rigidProduct: boolean;
  mikaBox: boolean;
}

interface PropsTag {
  active: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Tag: React.FC<PropsTag> = ({ active, children, onClick }) => {
  return (
    <>
      {active ? (
        <h3 className="active-tag" onClick={onClick}>
          {children}
        </h3>
      ) : (
        <h3 onClick={onClick}>{children}</h3>
      )}
    </>
  );
};

const routes = ["all-products", "cup", "ps-foam", "rigid-product", "mika-box"];

const initialStatus = {
  allProduct: false,
  cup: false,
  psFoam: false,
  rigidProduct: false,
  mikaBox: false,
};

const nameMapping = {
  allProduct: "All Product",
  cup: "Cup",
  psFoam: "PS Foam",
  rigidProduct: "Rigid Product",
  mikaBox: "Mika Box",
};

const camelize = (s: string) => {
  return s.replace(/-./g, (x) =>
    x[1].toUpperCase()
  ) as keyof typeof nameMapping;
};

const ListProducts: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [productSelected, setProductSelected] = useState<string>("All Product");
  const [tagStatus, setTagStatus] = useState<TagStatus>(initialStatus);
  const navigate = useNavigate();
  const onItemClick = useCallback(
    (productId: number) => navigate(`/products/${productId}`),
    [navigate]
  );

  const filterProductTracking = (productName: string) => {
    window.gtag("event", "click_tag_filter", {
      page: "product_list",
      category: productName,
    });
  };
  const onProductTracking = (productName: string) => {
    window.gtag("event", "click_product_card", {
      page: "product_list",
      category: productName,
    });
  };

  useEffect(() => {
    const determineActiveTag = () => {
      if (
        !routes.includes(String(searchParams.get("name"))) ||
        searchParams.get("name") === "all-product"
      ) {
        return setTagStatus({ ...initialStatus, allProduct: true });
      } else if (searchParams.get("name") === "cup") {
        setTagStatus({ ...initialStatus, cup: true });
      } else if (searchParams.get("name") === "ps-foam") {
        setTagStatus({ ...initialStatus, psFoam: true });
      } else if (searchParams.get("name") === "rigid-product") {
        setTagStatus({ ...initialStatus, rigidProduct: true });
      } else {
        setTagStatus({ ...initialStatus, mikaBox: true });
      }
    };
    determineActiveTag();
  }, [searchParams]);

  useEffect(() => {
    const updateSelectedProduct = () => {
      const camelizedString = camelize(String(searchParams.get("name")));
      if (camelizedString in nameMapping) {
        const camelizedString = camelize(String(searchParams.get("name")));
        setProductSelected(nameMapping[camelizedString]);
      }
    };
    updateSelectedProduct();
  }, [searchParams]);

  return (
    <div className="products">
      <div className="products__tags">
        <h1>{productSelected}</h1>
        <div className="products__tags__tag">
          <Tag
            active={tagStatus.allProduct}
            onClick={() => {
              setSearchParams({ name: "all-product" });
              filterProductTracking("All Product");
            }}
          >
            All Product
          </Tag>
          <Tag
            active={tagStatus.cup}
            onClick={() => {
              setSearchParams({ name: "cup" });
              filterProductTracking("Cup");
            }}
          >
            Cup
          </Tag>
          <Tag
            active={tagStatus.psFoam}
            onClick={() => {
              setSearchParams({ name: "ps-foam" });
              filterProductTracking("PS Foam");
            }}
          >
            PS Foam
          </Tag>
          <Tag
            active={tagStatus.rigidProduct}
            onClick={() => {
              setSearchParams({ name: "rigid-product" });
              filterProductTracking("Rigid Product");
            }}
          >
            Rigid Product
          </Tag>
          <Tag
            active={tagStatus.mikaBox}
            onClick={() => {
              setSearchParams({ name: "mika-box" });
              filterProductTracking("Mika Box");
            }}
          >
            Mika Box
          </Tag>
        </div>
      </div>
      <div className="products__items">
        {tagStatus.allProduct === true ? (
          <>
            {products.map((product) => {
              return (
                <React.Fragment key={product.category}>
                  {product.items.map((item) => {
                    return (
                      <div
                        className="products__items__item"
                        key={item.name}
                        onClick={() => {
                          onItemClick(item.id);
                          onProductTracking(item.name);
                        }}
                      >
                        <LazyLoadImage
                          src={item.image}
                          alt="product-item"
                          effect="blur"
                        />
                        <p>{item.name}</p>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <>
            {products
              .filter((prod) => prod.category === searchParams.get("name"))
              .map((product) => {
                return (
                  <React.Fragment key={product.category}>
                    {product.items.map((item) => {
                      return (
                        <div
                          className="products__items__item"
                          key={item.name}
                          onClick={(e) => onItemClick(item.id)}
                        >
                          <LazyLoadImage
                            src={item.image}
                            alt="product-item"
                            effect="blur"
                          />
                          <p>{item.name}</p>
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default ListProducts;
