export const product =  [
    {
      name: "Cup",
      route:"cup"
    },
    {
      name: "PS Foam",
      route:"ps-foam"
    },
    {
      name: "Mika Box",
      route:"mika-box"
    },
    {
      name: "Rigid Product",
      route:"rigid-product"
    },
    {
      name: "All Product",
      route: "all-product"
    }

  ]
export const contact = [
  {
    name: "Informasi Kontak",
    route:"contact-info"
  },
  {
    name: "FAQ",
    route: "faq"
  },
  
]

export const about = [
  {
    name: "Informasi Perusahaan",
    route:"company"
  },
  {
    name: "Galeri",
    route: "gallery"
  }
  
]
