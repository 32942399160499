export const items = [
    {
        id: 1,
        name: "GPC 78 - 10",
        image: "https://ik.imagekit.io/chronoarc/Cup/gpc78-10?ik-sdk-version=javascript-1.4.3&updatedAt=1658471185889",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "78 x 51 x 103 mm"
            },
            {
                key: "Volume",
                value: "270 ml"
            }
        ],
        category: "cup"
    },
    {
        id: 2,
        name: "GPC 92 - 8",
        image: "https://ik.imagekit.io/chronoarc/Cup/2._gpc92-8_pmnD5kblZT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470871186",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "92 x 70 x 49 mm"
            },
            {
                key: "Volume",
                value: "220 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 3,
        name: "GPC 92 - 12",
        image: "https://ik.imagekit.io/chronoarc/Cup/3._gpc92-12_qgmWpO11BmZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470872367",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "92 x 58 x 94 mm"
            },
            {
                key: "Volume",
                value: "360 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 4,
        name: "GPC 92 - 14",
        image: "https://ik.imagekit.io/chronoarc/Cup/4._gpc92-14_u6WBIBVqC.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470866184",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "92 x 58 108 mm"
            },
            {
                key: "Volume",
                value: "400 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 5,
        name: "GPC 98 - 16",
        image: "https://ik.imagekit.io/chronoarc/Cup/5._gpc98-16_jYtheynJk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470867763",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "98 x 60 x 120 mm"
            },
           
            {
                key: "Volume",
                value: "550 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 6,
        name: "GPC 98 - 22",
        image: "https://ik.imagekit.io/chronoarc/Cup/6._gpc98-22_uWBLWFI0w.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470869982",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran ",
                value: "98 x 60 x 140 mm"
            },
            {
                key: "Volume",
                value: "650 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 7,
        name: "GPC 98 - 24",
        image: "https://ik.imagekit.io/chronoarc/Cup/7._gpc98-24_Lf5MnBpqTF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470871446",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "98 x 61 x 152 mm"
            },
           
            {
                key: "Volume",
                value: "670 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 8,
        name: "GPC 98 - 28/30",
        image: "https://ik.imagekit.io/chronoarc/Cup/8._gpc98-28_30_WtnxwEZ3ZL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470874790",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "98 x 59 x 181 mm"
            },
            {
                key: "Volume",
                value: "770 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 9,
        name: "GPC PP - 14",
        image: "https://ik.imagekit.io/chronoarc/Cup/9._gpcpp-14_I6sYiZjOg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470873368",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "93 x 60 x 108 mm"
            },
            {
                key: "Volume",
                value: "450 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 10,
        name: "GPC PP - 16",
        image: "https://ik.imagekit.io/chronoarc/Cup/10._gpcpp-16_1wRP_7A9q.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470863538",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "93 x 58 x 128 mm"
            },
            {
                key: "Volume",
                value: "500 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 11,
        name: "GPC PP - 22",
        image: "https://ik.imagekit.io/chronoarc/Cup/11._gpcpp-16_VRoMINN26.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470863828",
        amountMultiplier: 1000,
        unitName: "carton",
        specification: [
            {
                key: "Ukuran",
                value: "93 x 61 x 153 mm"
            },
            {
                key: "Volume",
                value: "640 ml"
            },
        ],
        category: "cup"
    },
    {
        id: 12,
        name: "GPS 01",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/1._gps01_dT2FEmgza.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471086722",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "185 x 125 x 75 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 13,
        name: "GPS 03",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/2._Gps03_pcfm-zZP3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471091837",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "175 x 175 x 75 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 14,
        name: "GPS 03s",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/3._Gps03s_MQruUCIoA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471096324",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "175 x 175 x 75 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 14,
        name: "GPS 05",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/4._Gps05_wTIjOD7HKt.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471097340",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "235 x 220 x 30 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 15,
        name: "GPS 08",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/5._Gps08_6UPGqGL-n.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471097849",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "266 x 177 x 55 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 16,
        name: "GPS 10",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/6._Gps10_UNfWeEsVI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471098289",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "270 x 197 x 45 mm"
            },
        ],
        category: "ps-foam"
    },
    
    {
        id: 18,
        name: "HB",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/7._hb_G-s_OPpl3.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471098591",
        amountMultiplier: 200,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "120 x 120 x 75 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 19,
        name: "TGP 1",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/8._Tgp1_002c1kL9f.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471099044",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "223 x 150 x 28  mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 20,
        name: "TGP 2",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/9._Tgp2_-sW-80BuR.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471099528",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "190 x 130 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 21,
        name: "TGP 3",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/10._Tgp3_tGqRRgRei.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471086986",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "190 x 120 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 22,
        name: "TGP 4",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/11._Tgp4_7gDtwVsnl.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471087685",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "160 x 120 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 23,
        name: "TGP 5",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/12._Tgp5_-cI63MZzb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471087986",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "180 x 115 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 24,
        name: "TGP 6",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/13._Tgp6_bz2dKouO8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471088408",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "210 x 100 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 25,
        name: "TGP 7",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/14._Tgp7_21PYqgSg4.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471088825",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "120 x 120 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 26,
        name: "TGP 8",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/15._Tgp8_KOVEtXC8A.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471089388",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "240 x 170 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 27,
        name: "TGP 11",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/16._Tgp11_VvyhVLH4s.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471089932",
        amountMultiplier: 500,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "143 x 143 x 17 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 28,
        name: "MGP 02",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/17._Mgp02_QiU-loWtj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471090355",
        amountMultiplier: 300,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "130 x 70 x 57 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 29,
        name: "MGP 04",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/18._Mgp04__4DaMbBQg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471090884",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "195 x 105 x 62 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 30,
        name: "MGP 06",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/19._Mgp06_IdOm-njlz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471091395",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "195 x 100 x 65 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 31,
        name: "MGP 07",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/20._Mgp07_ooxC0BN1f.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471092327",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "105 x 60 x 41 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 32,
        name: "MGP 09",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/21._Mgp09_JwevFaDuI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471092742",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "144 x 84 x 68 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 33,
        name: "KGP 01",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/22._Kgp01_KlLRUOmHk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471093348",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "186 x 130 x 38 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 34,
        name: "KGP 02",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/23._Kgp02_KQO3mnFlp.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471093551",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "208 x 140 x 38 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 35,
        name: "KGP 03",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/24._Kgp03_3M5t9z2LR.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471094070",
        amountMultiplier: 100,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "225 x 175 x 45 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 36,
        name: "KGP 07",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/25._Kgp07_MOJwMXC3S.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471094522",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "205 x 175 x 45 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 37,
        name: "PGP 6",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/26._Pgp6_z1ggjnJBn.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471094733",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "152 x 40 x 19 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 38,
        name: "PGP 7.5",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/27._Pgp7_UBh0j-Qrb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471095079",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "195 x 135 x 18 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 39,
        name: "PGP 9",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/28._Pgp9_PMyDoAVAy.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471095605",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "230 x 165 x 20 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 40,
        name: "PGP 10",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/29._Pgp10_TxGyh4l3D.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471096083",
        amountMultiplier: 250,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "263 x 190 x 21 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 41,
        name: "SP",
        image: "https://ik.imagekit.io/chronoarc/PSFoam/30._Sp_vDm_ZBq-5.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471096759",
        amountMultiplier: 1500,
        unitName: "plb",
        specification: [
            {
                key: "Ukuran",
                value: "70 x 40 x 20 mm"
            },
        ],
        category: "ps-foam"
    },
    {
        id: 42,
        name: "GX - 1",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/1._gx1_4TTj4sYtq.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471025998",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "282 x 267 x 80 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 43,
        name: "GX - 1 A",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/2._gx1a_4EaCHhjJm.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471029831",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "230 x 220 x 54 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 44,
        name: "GX - 2 A",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/3._gx2a_97AImhs-Q.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471030154",
        amountMultiplier: 1000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "235 x 150 x 60 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 46,
        name: "GX - 3",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/4._gx3_zp_LLoh_C.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471030629",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "185 x 123 x 35 mm"
            },

        ],
        category: "mika-box"
    },
    {
        id: 47,
        name: "GX - 4",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/5._gx4_xMp1smLgS.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471030991",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "163 95 x 30 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 48,
        name: "GX - 5",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/6._gx5_lwG84pJxn.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471031321",
        amountMultiplier: 3000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "125 x 93 x 30 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 49,
        name: "GX - 6 A",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/7._gx6a_wLJWnQmI_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471031808",
        amountMultiplier: 6000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "85 x 85 x 30 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 50,
        name: "GX - 18",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/8._gx18_ITvwwdSDt.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471032201",
        amountMultiplier: 1500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "180 x 180 x 30 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 51,
        name: "GX - 20",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/9._gx20_n3jYa5-Oy.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471032784",
        amountMultiplier: 1500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "200 x 200 x 30 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 52,
        name: "GX - HB",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/10._gxhb_ZEMmMIT1U.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471026330",
        amountMultiplier: 1500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "128 x 130 x 66 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 53,
        name: "GX - HD",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/11._gxhd_Ig5dThB3c.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471026633",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "196 x 110 x 58 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 54,
        name: "GX - B06",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/12._gxb06_PvNMl1wtV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471027056",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "170 x 100 x 80 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 55,
        name: "GX - TGP 1",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/13._Gxtgp1_MR9jZScRC.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471027616",
        amountMultiplier: 1500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "225 x 150 x 18 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 56,
        name: "GX - TGP 2",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/14._Gxtgp2_ghA2_aKb0.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471027800",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "190 x 130 x 18 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 57,
        name: "GX - TGP 3",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/15._Gxtgp3_xgjg1zL8D.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471028496",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "190 x 120 x 18 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 58,
        name: "GX - TGP 4",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/16._Gxtgp4_UU8JzlLUu.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471029390",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "160 x 120 x 15 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 59,
        name: "GX - TGP 5",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/17._Gxtgp5_J9YOfxbY9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471029956",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "180 x 115 x 18 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 60,
        name: "GX - TGP 6",
        image: "https://ik.imagekit.io/chronoarc/MikaBox/18._Gxtgp6_vpXRSSSZ6.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658471029481",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "210 x 100 x 18 mm"
            },
        ],
        category: "mika-box"
    },
    {
        id: 61,
        name: "LBHX - 01",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/1._lbhx1_6yIVqjwz-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470908472",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "265 x 188 x 41 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 62,
        name: "LBHX - 02",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/2._lbhx2_Ygl1Uxc__s.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470910496",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "230 x 170 x 52 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 63,
        name: "LBHX - 02A",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/3._lbhx2a_DjIyQ8v0TO.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470915720",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "230 x 170 x 52 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 64,
        name: "LBHX - 03",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/4._lbhx3_UcTtlz0kyp.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470917929",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "262 x 208 x 40 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 65,
        name: "LBHX - 04",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/5._lbhx4__2OIHT0Evv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470919189",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "230 x 170 x 34 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 66,
        name: "LBHX - 05",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/6._lbhx5_vH8w87Bged.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470918104",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "257 x 182 x 42 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 67,
        name: "LBHX - 06",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/7._lbhx6_VR-zIFMG8W.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470919180",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "240 x 177 x 50 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 68,
        name: "LBHX - 07",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/8._lbhx7_z-j3Hdiujv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470919205",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "210 x 155 x 44 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 69,
        name: "LBHX - 08",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/9._lbhx8_DJazie47e-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470920207",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "175 x 115 x 44  mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 70,
        name: "LBHX - 09",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/10._lbhx9_6AmYoSMK1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470907149",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "178 x 117 x 331 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 71,
        name: "LBHX - 10",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/11._lbhx10_pkxOxjRJjF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470908050",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "180 x 130 x 42 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 72,
        name: "LBHX - 11",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/12._lbhx11_SCHSkE7Cb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470908896",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "184 x 144 x 54 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 73,
        name: "LBHX - 13",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/13._lbhx13_eHYzPRrgM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470910248",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "162 x 87 x 41 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 74,
        name: "LBHX - 14",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/14._lbhx14_y-5fd-io8I.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470910887",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "164 x 113 x 44 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 75,
        name: "LBHX - BR1",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/15._lbhxbr1_pcbvR3B30.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470909302",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "177 x 126 x 56 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 76,
        name: "LBHX - BR2",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/16._lbhxbr2_3Dcf2jWxv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470910125",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "240 x 126 x 56 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 76,
        name: "LBHX - BR3",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/17._lbhxbr3_lfv-DdRE1.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470910609",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "317 x 126 x 56 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 77,
        name: "LBHX - NACHOS",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/18._lbhx-nachos_HAJuXBiga.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470911795",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "192 x 137 x 25 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 78,
        name: "MGX - 1",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/19._mgx1_kKj1CX5Qht.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470912693",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "187 x 187 x 38 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 79,
        name: "MGX - 6",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/20._mgx6_Us0-ZclZRh.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470911704",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "180 x 78 x 52 mm"
            },
            {
                key: "Volume",
                value: "450 ml"
            }
        ],
        category: "rigid-product"
    },
    {
        id: 80,
        name: "MGX - 7",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/21._mgx7_NisRQO0k10.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470912011",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "180 x 110 x 52 mm"
            },
            {
                key: "Volume",
                value: "700 ml"
            }
        ],
        category: "rigid-product"
    },
    {
        id: 81,
        name: "MGX - 7.5",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/22._mgx7.5_mY1JLXtpBc.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470913577",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "187 x 110 x 67 mm"
            },
            {
                key: "Volume",
                value: "950 ml"
            }
        ],
        category: "rigid-product"
    },
    {
        id: 82,
        name: "GX - 1910",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/23._gx1910_eIARk0dCPs.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470912171",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "225 x 139 x 29 mm"
            },
        ],
        category: "rigid-product"
    },
    {
        id: 83,
        name: "SP VISIN",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/24._SP_Visin_etLVEY3VTO.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470913374",
        amountMultiplier: 5000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "60 x 13 mm"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 84,
        name: "P.6",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/25._P6_XznoYkRaYk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470913610",
        amountMultiplier: 2000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "149 x 20 mm"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 85,
        name: "P.7,5",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/26._P7.5_yaA8r6S-HG.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470915179",
        amountMultiplier: 1000,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "191 x 20 mm"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 86,
        name: "P.9",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/27._P9_YfzMIzyzcF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470915542",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "230 x 20 mm"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 87,
        name: "GRB - 01",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/28._grb1_Vw7gshLzgT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470914770",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "118 x 118 x 65 mm"
            },
            {
                key: "Volume",
                value: "550 ml"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 87,
        name: "GRB - 02",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/29._grb2_HlOtqYIskJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470915413",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "118 x 118 x 53 mm"
            },
            {
                key: "Volume",
                value: "450 ml"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 88,
        name: "GRB - 03",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/30._grb3_0P1umnnAma.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470917026",
        amountMultiplier: 500,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "118 x 118 x 40 mm"
            },
            {
                key: "Volume",
                value: "350 ml"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 89,
        name: "EGG - 6",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/31._eggtray6_55ObSyo_Xo.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470917521",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "155 x 105 x 70 mm"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 90,
        name: "EGG - 10",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/32._eggtray10_6cYfoZKf-Z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470916722",
        amountMultiplier: 300,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "252 x 107 x 72 mm"
            },

        ],
        category: "rigid-product"
    },
    {
        id: 91,
        name: "SANDWICH",
        image: "https://ik.imagekit.io/chronoarc/RigidProduct/33._sandwich_tmp-72ek8Q.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658470917443",
        amountMultiplier: 600,
        unitName: "Carton",
        specification: [
            {
                key: "Ukuran",
                value: "174 x 78 x 78 mm"
            },

        ],
        category: "rigid-product"
    },

]
export const products = [
    {
        category: "cup",
        items: items.filter((item) => item.category === 'cup')
    },
    {
        category: "ps-foam",
        items: items.filter((item) => item.category === 'ps-foam')
    },
    {
        category: "rigid-product",
        items: items.filter((item) => item.category === 'rigid-product')
    },
    {
        category: "mika-box",
        items: items.filter((item) => item.category === 'mika-box')
    },

]
