export const headline = [
    {
        title: "Solusi Pengemasan yang Inovatif dan Berkelanjutan",
        text: "Selamat datang di Gosyen. Kami adalah perusahaan yang berdedikasi untuk produksi dan promosi kemasan yang inovatif dan berkelanjutan sejak tahun 2003."
    },
    {
        title: "Makanan Anda Layak Dikemas dengan Baik",
        text: "Pastikan pelanggan Anda mengingat pengalaman mereka dengan kemasan terbaik. Pelajari lebih lanjut tentang produk kami."
    },
    {
        title: "Inovasi diawali dengan Kualitas",
        text: "Pengalaman dalam industri kemasan membantu kami mengidentifikasi formula terbaik untuk memenuhi kebutuhan pelanggan kami. Kami memastikan bahwa kemasan makanan kami selalu memenuhi standar."
    }
]
