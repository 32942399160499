import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogoWhatsapp } from "../../../assets";
import QuotationSummary from "../../../components/QuotationSummary";
import useLocalStorage from "../../../hooks/useLocalStorage";
import "./QuotationFormDesktop.scss";

interface Form {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  businessType?: string;
}

const QuotationFormDesktop: React.FC = () => {
  const [productsQuoted, setProductsQuoted] = useLocalStorage(
    "productsQuoted",
    [] as any[]
  );
  const [formData, setFormData] = useState<Form>({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    businessType: "",
  });

  const submitQuotation = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { firstName, lastName, email, companyName, businessType } = formData;
    const phoneNumber = "6285939765565";
    let text = "";
    if (companyName && businessType) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email} \nSaya berasal dari ${companyName} dari Industri ${businessType} \nSaya ingin memesan:`;
    }
    if (!companyName) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email} \nSaya berasal dari Industri ${businessType} \nSaya ingin memesan:`;
    }
    if (!businessType) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email} \nSaya berasal dari ${companyName} \nSaya ingin memesan:`;
    }
    if (!companyName && !businessType) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email}\nSaya ingin memesan:`;
    }
    if (productsQuoted.length !== 0) {
      for (let i = 0; i < productsQuoted.length; i++) {
        text += `\n- ${productsQuoted[i].product.name}: ${productsQuoted[i].units} ${productsQuoted[i].product.unitName}`;
      }
    }
    window.gtag("event", "submit_quotation", {
      page: "quotation_form",
    });
    setProductsQuoted([]);
    const waLink = `https://wa.me/${phoneNumber}?text=${text}`;
    window.location.replace(encodeURI(waLink));

    return null;
  };

  return (
    <div className="quotation-form-desktop">
      <div className="quotation-form-desktop__contact">
        <h1>Request For Quotation</h1>
        <h3>Contact Information</h3>
        <form
          className="quotation-form-desktop__contact__form"
          onSubmit={submitQuotation}
        >
          <div className="quotation-form-desktop__contact__form__name-container">
            <div className="quotation-form-desktop__contact__form__input">
              <label htmlFor="firstName">First Name</label>
              <input
                name="firstName"
                type="text"
                id="firstName"
                required={true}
                onChange={(e) => {
                  setFormData({ ...formData, firstName: e.target.value });
                }}
              />
            </div>
            <div className="quotation-form-desktop__contact__form__input">
              <label htmlFor="lastName">Last Name</label>
              <input
                name="lastName"
                type="text"
                id="lastName"
                required={true}
                onChange={(e) => {
                  setFormData({ ...formData, lastName: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="quotation-form-desktop__contact__form__input">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              id="email"
              required={true}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
            />
          </div>
          <div className="quotation-form-desktop__contact__form__input">
            <label htmlFor="companyName">Company Name</label>
            <input
              name="companyName"
              type="text"
              id="companyName"
              onChange={(e) => {
                setFormData({ ...formData, companyName: e.target.value });
              }}
            />
          </div>
          <div className="quotation-form-desktop__contact__form__input">
            <label htmlFor="businessType">Business Type</label>
            <input
              name="businessType"
              type="text"
              id="businessType"
              onChange={(e) => {
                setFormData({ ...formData, businessType: e.target.value });
              }}
            />
          </div>
          <div className="quotation-form-desktop__contact__form__button">
            <div className="quotation-form-desktop__contact__form__button__back">
              <Link to="/products">
                <span>&#60;</span> <h3>Jelajahi Produk</h3>
              </Link>
            </div>
            <button type="submit">
              <img src={LogoWhatsapp} alt="logo-whatsapp" />
              Submit Quotation
            </button>
          </div>
        </form>
      </div>
      <div className="quotation-form-desktop__summary">
        <QuotationSummary
          productsQuoted={productsQuoted}
          setProductsQuoted={setProductsQuoted}
        />
      </div>
    </div>
  );
};

export default QuotationFormDesktop;
