import React from "react";
import { LogoGosyen, LogoInstagram } from "../../assets";
import "./Footer.scss";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
const Footer: React.FC = () => {
  const mobile = useMediaQuery({
    query: "(max-width: 640px)",
  });

  const onClickProductFooter = (productName: string) => {
    window.gtag("event", "click_products_footer", {
      category: productName,
    });
  };
  return (
      <div className="footer">
          <div className="logo-gosyen">
              <img src={LogoGosyen} alt="logo-gosyen" />
          </div>
          <div
              className="footer-menu-bar"
              style={{ flexDirection: mobile ? "column" : "row" }}
          >
              <div className="menu-child">
                  <div className="menu-title">Produk</div>
                  <Link
                      to="/products?name=cup"
                      onClick={() => onClickProductFooter("Cup")}
                  >
                      <div className="menu-content">Cup</div>
                  </Link>
                  <Link
                      to="/products?name=ps-foam"
                      onClick={() => onClickProductFooter("PS Foam")}
                  >
                      <div className="menu-content">PS Foam</div>
                  </Link>
                  <Link
                      to="/products?name=mika-box"
                      onClick={() => onClickProductFooter("Mika Box")}
                  >
                      <div className="menu-content">Mika Box</div>
                  </Link>
                  <Link
                      to="/products?name=rigid-product"
                      onClick={() => onClickProductFooter("Rigid Product")}
                  >
                      <div className="menu-content">Rigid Product</div>
                  </Link>
              </div>
              <div className="menu-child">
                  <div className="menu-title">Tentang Kami</div>
                  <Link to="/aboutus#company">
                      <div className="menu-content">Informasi Perusahaan</div>
                  </Link>
                  <Link to="/aboutus#gallery">
                      <div className="menu-content">Galeri</div>
                  </Link>
              </div>
              <div className="menu-child">
                  <div className="menu-title">Hubungi Kami</div>
                  <Link to="/contactus#contact-info">
                      <div className="menu-content">Informasi Kontak</div>
                  </Link>
                  <Link to="/contactus#faq">
                      <div className="menu-content">FAQ</div>
                  </Link>
              </div>
              <div className="menu-child" id="address">
                  <div className="menu-title">Kantor Pusat: </div>
                  <div className="menu-content">
                      <p>
                          Kantor Pusat: Jl. Jend. Gatot Subroto Km. 4, Kawasan
                          Industri
                      </p>
                      <p>
                          Kalisabi No. 7 Cibodas Tangerang, Banten 15138,
                          Indonesia
                      </p>
                      <p>Telp:(62-21) 5577 3477</p>
                  </div>
              </div>
          </div>
          <div className="contact-copyright">
              <div className="instagram-logo">
                  <img src={LogoInstagram} alt="logo-instagram"></img>
                  <div>
                      <a href="https://www.instagram.com/gosyenpacific/?hl=id">
                          Ikuti kami di Instagram
                      </a>
                  </div>
              </div>
              <div>
                  © 2022 Gosyen | Gosyen adalah merek milik PT. Gosyen Pacific
                  Suksesmakmur Terdaftar pada Direktorat Jendral Kekayaan
                  Intelektual Republik Indonesia.
              </div>
          </div>
      </div>
  );
};

export default Footer;
