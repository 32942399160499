import React, { useEffect, useState } from "react";
import { OrderFlowImg, OrderFlowMobileImg } from "../../assets";

import "./OrderFlow.scss";

const OrderFlow: React.FC = () => {
  const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 650);

  const responsive = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", responsive);
    return () => window.removeEventListener("resize", responsive);
  });
  return (
    <div className="order-flow">
      <h1>Cara Memesan</h1>
      {isDesktop ? (
        <img width={"90%"} src={OrderFlowImg} alt="Order-Flow" />
      ) : (
        <img src={OrderFlowMobileImg} alt="Order Flow" />
      )}
    </div>
  );
};

export default OrderFlow;
