import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DownArrow, LogoWhatsapp, UpArrow } from "../../../assets";
import QuotationSummary from "../../../components/QuotationSummary";
import useLocalStorage from "../../../hooks/useLocalStorage";
import "./QuotationFormMobile.scss";

interface Form {
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  businessType?: string;
}

const QuotationFormMobile: React.FC = () => {
  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [productsQuoted, setProductsQuoted] = useLocalStorage(
    "productsQuoted",
    [] as any[]
  );
  const [formData, setFormData] = useState<Form>({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    businessType: "",
  });

  const submitQuotation = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { firstName, lastName, email, companyName, businessType } = formData;
    const phoneNumber = "6285939765565";
    let text = "";
    if (companyName && businessType) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email} \nSaya berasal dari ${companyName} dari Industri ${businessType} \nSaya ingin memesan:`;
    }
    if (!companyName) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email} \nSaya berasal dari Industri ${businessType} \nSaya ingin memesan:`;
    }
    if (!businessType) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email} \nSaya berasal dari ${companyName} \nSaya ingin memesan:`;
    }
    if (!companyName && !businessType) {
      text = `Halo, saya ${firstName} ${lastName} \nEmail Saya: ${email}\nSaya ingin memesan:`;
    }
    if (productsQuoted.length !== 0) {
      for (let i = 0; i < productsQuoted.length; i++) {
        text += `\n- ${productsQuoted[i].product.name}: ${productsQuoted[i].units} ${productsQuoted[i].product.unitName}`;
      }
    }
    setProductsQuoted([]);
    window.gtag("event", "submit_quotation", {
      page: "quotation-form",
    });
    const waLink = `https://wa.me/${phoneNumber}?text=${text}`;
    window.location.replace(encodeURI(waLink));
    return null;
  };

  return (
    <div className="quotation-form">
      <h1>Request For Quotation</h1>
      <div className="quotation-form__summary">
        {summaryOpen ? (
          <>
            <div
              className="quotation-form__summary__header"
              onClick={() => setSummaryOpen(!summaryOpen)}
            >
              <h3>Hide quote summary</h3>
              <img src={UpArrow} alt="up-arrow" />
            </div>
            <QuotationSummary
              productsQuoted={productsQuoted}
              setProductsQuoted={setProductsQuoted}
            />
          </>
        ) : (
          <div
            className="quotation-form__summary__header"
            onClick={() => setSummaryOpen(!summaryOpen)}
          >
            <h3>Show quote summary</h3>

            <img src={DownArrow} alt="down-arrow" />
          </div>
        )}
      </div>
      <div className="quotation-form__contact">
        <h3>Contact Information</h3>
        <form
          className="quotation-form__contact__form"
          onSubmit={submitQuotation}
        >
          <div>
            <label htmlFor="firstName">First Name</label>
            <input
              name="firstName"
              type="text"
              id="firstName"
              required={true}
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlFor="lastName">Last Name</label>
            <input
              name="lastName"
              type="text"
              id="lastName"
              required={true}
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              type="email"
              id="email"
              required={true}
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlFor="companyName">Company Name</label>
            <input
              name="companyName"
              type="text"
              id="companyName"
              onChange={(e) => {
                setFormData({ ...formData, companyName: e.target.value });
              }}
            />
          </div>
          <div>
            <label htmlFor="businessType">Business Type</label>
            <input
              name="businessType"
              type="text"
              id="businessType"
              onChange={(e) => {
                setFormData({ ...formData, businessType: e.target.value });
              }}
            />
          </div>
          <button type="submit">
            <img src={LogoWhatsapp} alt="logo-whatsapp" />
            Submit Quotation
          </button>
        </form>
        <div className="quotation-form__contact__back">
          <Link to="/products">
            <span>&#60;</span> <h3>Jelajahi Produk</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuotationFormMobile;
