import React from "react";
import { RigidProduct, Cup, PsFoam, MikaBox } from "../../assets";
import "./ProductRange.scss";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const ProductRange: React.FC = () => {
  const mobile = useMediaQuery({
    query: "(max-width: 640px)",
  });

  const onClickProductRange = (productName: string) => {
    window.gtag('event', 'click_product_range', {
      'category': productName
    })
  }

  return (
    <div className="product-range">
      <div className="title">Produk Kami</div>
      <div
        className="product-placeholder"
        style={{ flexDirection: mobile ? "column" : "row" }}
      >
        <div className="product-type">
          <Link to="/products?name=rigid-product" onClick={() => onClickProductRange("Rigid Product")}>
            <div className="image-placeholder">
              <img src={RigidProduct} alt="rigid-product"></img>
            </div>
          </Link>
          <div className="product-name">Rigid Product</div>
        </div>
        <div className="product-type">
          <Link to="/products?name=cup">
            <div className="image-placeholder" onClick={() => onClickProductRange("Cup")}>
              <img src={Cup} alt="cup"></img>
            </div>
          </Link>
          <div className="product-name">Cup</div>
        </div>
      </div>
      <div
        className="product-placeholder"
        style={{ flexDirection: mobile ? "column" : "row" }}
      >
        <div className="product-type">
          <Link to="/products?name=ps-foam" onClick={() => onClickProductRange("PS Foam")}>
            <div className="image-placeholder">
              <img src={PsFoam} alt="ps-foam"></img>
            </div>
          </Link>
          <div className="product-name">PS Foam</div>
        </div>
        <div className="product-type">
          <Link to="/products?name=mika-box" onClick={() => onClickProductRange("Mika Box")}>
            <div className="image-placeholder">
              <img src={MikaBox} alt="mika-box"></img>
            </div>
          </Link>
          <div className="product-name">Mika Box</div>
        </div>
      </div>
    </div>
  );
};

export default ProductRange;
