import React, { useEffect, useState } from "react";
import QuotationFormDesktop from "./QuotationFormDesktop";
import QuotationFormMobile from "./QuotationFormMobile";

const QuotationForm = () => {
  const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 650);

  const responsive = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", responsive);
    return () => window.removeEventListener("resize", responsive);
  });
  return isDesktop ? <QuotationFormDesktop /> : <QuotationFormMobile />;
};
export default QuotationForm;
