import React, { useState, useEffect } from "react";
import "./HeadlineCarousel.scss";
import {
  HeadlineImage1,
  HeadlineImage2,
  HeadlineImage3,
  HeadlineImage1Mobile,
  HeadlineImage2Mobile,
  HeadlineImage3Mobile,
} from "../../../assets";
import { headline } from "../../../data/carousel";
import Button from "../../../components/Button";
import TextTransition, { presets } from "react-text-transition";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

interface Props { }

const HeadlineCarousel: React.FC<Props> = (props: Props) => {
  const images = [HeadlineImage1, HeadlineImage2, HeadlineImage3];
  const imagesMobile = [
    HeadlineImage1Mobile,
    HeadlineImage2Mobile,
    HeadlineImage3Mobile,
  ];
  const mobile = useMediaQuery({
    query: "(max-width: 640px)",
  });
  const [index, setIndex] = useState<number>(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((index) => (index === 2 ? 0 : index + 1));
    }, 10000);
    return () => clearTimeout(intervalId);
  }, []);

  const exploreOurProductTracking = () => {
    window.gtag("event", "explore_products", {
      page: "Home",
    });
  };

  return (
    <div className="headlineCarousel">
      <div className="headlineCarousel__item">
        <div className="headlineCarousel__item__text">
          <div className="headlineCarousel__item__text__title">
            <TextTransition springConfig={presets.slow}>
              <p>{headline[index].title}</p>
            </TextTransition>
          </div>
          <div className="headlineCarousel__item__text__text">
            <TextTransition springConfig={presets.slow}>
              <p>{headline[index].text}</p>
            </TextTransition>
          </div>
          <div className="headlineCarousel__item__text__button">
            <Link to="/products" onClick={exploreOurProductTracking}>
              <Button>Jelajahi Produk</Button>
            </Link>
          </div>
        </div>
        <div
          className="headlineCarousel__item__image"
          style={{
            backgroundImage: `url(${mobile ? imagesMobile[index] : images[index]
              })`,
          }}
        />
      </div>
    </div>
  );
};

export default HeadlineCarousel;
