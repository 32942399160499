import React from "react";
import {
    AboutUsBanner,
    MedalWhiteLogo,
    PlasticsWhiteLogo,
    TrashWhiteLogo,
} from "../../assets";
import { ourStory, visMis } from "../../data/aboutUs";
import Gallery from "./Gallery";
import "./AboutUs.scss";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { uvp } from "../../data/uvp";

const AboutUs: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="aboutUsContainer" id="company">
            <div
                className="bannerContainer"
                style={{ backgroundImage: `url(${AboutUsBanner})` }}
            />
            <div className="ourStoryContainer">
                <p className="ourStoryContainer__title">{ourStory.title}</p>
                <div className="ourStoryContainer__description">
                    <p className="ourStoryContainer__description__title">
                        Kisah Kami
                    </p>
                    <p className="ourStoryContainer__description__description">
                        {ourStory.description_1}
                        <br />
                        <br />
                        {ourStory.description_2}
                        <br />
                        <br />
                        {ourStory.description_3}
                    </p>
                </div>
            </div>
            <div className="visionMissionContainer">
                <div className="visionMissionContainer__vision">
                    <p className="visionMissionContainer__vision__title">
                        Visi Kami
                    </p>
                    <p className="visionMissionContainer__vision__description">
                        {visMis.vision}
                    </p>
                </div>
                <div className="visionMissionContainer__mission">
                    <p className="visionMissionContainer__mission__title">
                        Misi Kami
                    </p>
                    <p className="visionMissionContainer__mission__description">
                        {visMis.mission[0]}
                        <br />
                        <br />
                        {visMis.mission[1]}
                        <br />
                        <br />
                        {visMis.mission[2]}
                        <br />
                        <br />
                    </p>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                    fill="#E6F0EC"
                    fill-opacity="1"
                    d="M0,224L120,197.3C240,171,480,117,720,117.3C960,117,1200,171,1320,197.3L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                />
            </svg>
            <div className="whyChooseUsContainer">
                <p className="whyChooseUsContainer__title">
                    Mengapa harus kami
                </p>
                <AboutUsUVP />
            </div>
            <div className="ourPartnersContainer"></div>
            <div className="imageCarouselContainer">
                <Gallery />
            </div>
            <div className="learnMoreContainer">
                <p className="learnMoreContainer__text">
                    Pelajari lebih lanjut tentang produk kami
                </p>
                <Button onClick={() => navigate("/products")}>
                    Jelajahi Produk Kami
                </Button>
            </div>
        </div>
    );
};

const AboutUsUVP: React.FC = () => {
    return (
        <div className="aboutUsUvpContainer">
            <div className="aboutUsUvpList">
                <img
                    className="aboutUsUvpList__image"
                    style={{ objectFit: "contain" }}
                    src={PlasticsWhiteLogo}
                    alt="plastics-logo"
                />
                <div>
                    <p className="aboutUsUvpList__title">{uvp[0].title}</p>
                    <p className="aboutUsUvpList__text">{uvp[0].text}</p>
                </div>
            </div>
            <div className="aboutUsUvpList">
                <img
                    className="aboutUsUvpList__image"
                    style={{ objectFit: "contain" }}
                    src={TrashWhiteLogo}
                    alt="reusable-logo"
                />
                <div>
                    <p className="aboutUsUvpList__title">{uvp[1].title}</p>
                    <p className="aboutUsUvpList__text">{uvp[1].text}</p>
                </div>
            </div>
            <div className="aboutUsUvpList">
                <img
                    className="aboutUsUvpList__image"
                    style={{ objectFit: "contain" }}
                    src={MedalWhiteLogo}
                    alt="reusable-logo"
                />
                <div>
                    <p className="aboutUsUvpList__title">{uvp[2].title}</p>
                    <p className="aboutUsUvpList__text">{uvp[2].text}</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
