import React, { useState } from "react";
import { CloseIcon, DownArrow, Hamburger, LogoNavbar } from "../../assets";
import Button from "../Button";

import "./Navbar.scss";
import { about, contact, product } from "../../data/navigation";
import NavCards from "../NavCards";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import useLocalStorage from "../../hooks/useLocalStorage";

interface Status {
  product: boolean;
  contactus: boolean;
  aboutus: boolean;
}

interface NavbarProps {
  rfqCallback?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ rfqCallback }) => {
  const [productsQuoted] = useLocalStorage("productsQuoted", [] as any[]);
  const [navHiddenStatus, setNavHiddenStatus] = useState<Status>({
    product: true,
    contactus: true,
    aboutus: true,
  });
  const [mobileClicked, setMobileClicked] = useState<boolean>(false);

  return (
    <nav className="navbar">
      <div
        className="hamburger"
        onClick={() => setMobileClicked(!mobileClicked)}
      >
        {mobileClicked ? (
          <img src={CloseIcon} alt="close-icon" />
        ) : (
          <img src={Hamburger} alt="burger-icon" />
        )}
      </div>
      <Sidebar
        active={mobileClicked}
        setActive={setMobileClicked}
        product={product}
        about={about}
        contact={contact}
        navHiddenStatus={navHiddenStatus}
        setNavHiddenStatus={setNavHiddenStatus}
        onRfqClick={rfqCallback}
      />
      <Link to="/">
        <img src={LogoNavbar} alt="navbar-logo" />
      </Link>
      <div className="navbar__items">
        <div
          className="navbar__items__item"
          onMouseEnter={() =>
            setNavHiddenStatus({
              ...navHiddenStatus,
              product: false,
              aboutus: true,
              contactus: true,
            })
          }
          onMouseLeave={() =>
            setNavHiddenStatus({
              ...navHiddenStatus,
              product: true,
              aboutus: true,
              contactus: true,
            })
          }
        >
          <div>
            <p>Produk</p>
            <img src={DownArrow} alt="down-arrow" />
          </div>
          <NavCards
            menus={product}
            parentRoute="products"
            hidden={navHiddenStatus.product}
            onClick={() => {
              setNavHiddenStatus({
                ...navHiddenStatus,
                product: !navHiddenStatus.product,
                aboutus: true,
                contactus: true,
              });
            }}
          />
        </div>
        <div
          className="navbar__items__item"
          onMouseEnter={() =>
            setNavHiddenStatus({
              ...navHiddenStatus,
              contactus: false,
              aboutus: true,
              product: true,
            })
          }
          onMouseLeave={() =>
            setNavHiddenStatus({
              ...navHiddenStatus,
              contactus: true,
              aboutus: true,
              product: true,
            })
          }
        >
          <div>
            <p>Hubungi Kami</p>
            <img src={DownArrow} alt="down-arrow" />
          </div>
          <NavCards
            menus={contact}
            parentRoute="contactus"
            hidden={navHiddenStatus.contactus}
            onClick={() =>
              setNavHiddenStatus({
                ...navHiddenStatus,
                contactus: !navHiddenStatus.contactus,
                aboutus: true,
                product: true,
              })
            }
          />
        </div>
        <div
          className="navbar__items__item"
          onMouseEnter={() =>
            setNavHiddenStatus({
              ...navHiddenStatus,
              aboutus: false,
              product: true,
              contactus: true,
            })
          }
          onMouseLeave={() =>
            setNavHiddenStatus({
              ...navHiddenStatus,
              aboutus: true,
              product: true,
              contactus: true,
            })
          }
        >
          <div>
            <p>Tentang Kami</p>
            <img src={DownArrow} alt="down-arrow" />
          </div>
          <NavCards
            menus={about}
            parentRoute="aboutus"
            hidden={navHiddenStatus.aboutus}
            onClick={() =>
              setNavHiddenStatus({
                ...navHiddenStatus,
                aboutus: !navHiddenStatus.aboutus,
                product: true,
                contactus: true,
              })
            }
          />
        </div>
      </div>
      <Button
        flag={`${productsQuoted.length === 0 ? '' : productsQuoted.length}`}
        color="transparent"
        onClick={() => (rfqCallback !== undefined ? rfqCallback() : () => {})}
      >
        Request for Quotation
      </Button>
    </nav>
  );
};

export default Navbar;
