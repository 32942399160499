import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Navbar } from "./components";
import Footer from "./components/Footer";
import QuotationModal from "./components/QuotationModal";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import ListProducts from "./pages/Products/ListProducts";
import ProductDetail from "./pages/Products/ProductDetail";
import QuotationForm from "./pages/QuotationForm";

const Routing: React.FC = () => {
  const { pathname, hash, key } = useLocation();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
  return (
    <>
      <Navbar rfqCallback={() => setShowModal((showModal) => !showModal)} />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
        </Route>
        <Route path="/products" element={<ListProducts />} />
        <Route path="/aboutus">
          <Route index element={<AboutUs />} />
        </Route>
        <Route path="/products/:id" element={<ProductDetail />} />
        <Route path="/quotation" element={<QuotationForm />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
      <Footer />
      <QuotationModal
        isShow={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

function App() {
  return (
    <Router>
      <Routing />
    </Router>
  );
}

export default App;
