import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
    
  } from 'react'

type SetValue<T> = Dispatch<SetStateAction<T>>

function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
    const readValue = useCallback((): T => {
        if(typeof window === 'undefined') {
            return initialValue
        } 
        try {
            const item = localStorage.getItem(key)
            return item ? parseJSON(item) as T : initialValue
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error)
            return initialValue
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [storedValue, setStoredValue] = useState<T>(readValue)

    const setValue: SetValue<T> = useCallback(value => {
        if(typeof window === 'undefined') {
            console.warn(
                `Tried setting localStorage key “${key}” even though environment is not a client`,
            )
        }
        try {
            const newValue = value instanceof Function ? value(storedValue) : value
            localStorage.setItem(key, JSON.stringify(newValue))
            setStoredValue(newValue)
            window.dispatchEvent(new Event('local-storage-update'))
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setStoredValue(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLocalStorageUpdate = useCallback(() => {
        setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    window.addEventListener('local-storage-update', onLocalStorageUpdate);

    return [storedValue, setValue];
}

export default useLocalStorage;

function parseJSON<T>(value: string | null): T | undefined {
    try {
      return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch {
      console.log('parsing error on', { value })
      return undefined
    }
  }
