import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TrashIcon } from '../../../assets';
import './QuotationModalItem.scss'

interface QuotationModalItemProps {
    product: any;
    onAddBtnClick: (prodId: number) => void;
    onDecBtnClick: (prodId: number) => void;
    onRemoveBtnClick: (prodId: number) => void;
    onUnitsChange: (prodId: number, units: number) => void;
}

const QuotationModalItem: React.FC<QuotationModalItemProps> = ({
    product, 
    onAddBtnClick, 
    onDecBtnClick, 
    onRemoveBtnClick, 
    onUnitsChange
}) => {
  function toPascalCase(text: string) {
    return text.replace(/(^\w|-\w)/g, clearAndUpper);
  }

  function clearAndUpper(text: string) {
    return text.replace(/-/, " ").toUpperCase();
  }
  return (
      <div className="quotationModalItemContainer">
          <div className="quotationModalItemImage">
              <LazyLoadImage
                  src={product.product.image}
                  alt="product-img"
                  width={"100%"}
              />
          </div>
          <div className="quotationModalItemInformation">
              <p className="quotationModalItemInformation__name">
                  {product.product.name}
              </p>
              <p className="quotationModalItemInformation__category">
                  {toPascalCase(product.product.category)}
              </p>
              <br />
              <p className="quotationModalItemInformation__quantityText">
                  Kuantitas
              </p>
              <div className="quotationModalItemInformation__quantity">
                  <div className="quotationModalItemInformation__quantity__plusmin">
                      <button
                          className="qtyButton"
                          onClick={() =>
                              onDecBtnClick !== undefined
                                  ? onDecBtnClick(product.product.id)
                                  : () => {}
                          }
                      >
                          -
                      </button>
                      <input
                          className="productUnits"
                          type="number"
                          value={product.units}
                          onChange={(e) =>
                              onUnitsChange(
                                  product.product.id,
                                  Number(e.target.value)
                              )
                          }
                      />
                      {/* <p className="productUnits">{product.units}</p> */}
                      <button
                          className="qtyButton"
                          onClick={() =>
                              onAddBtnClick !== undefined
                                  ? onAddBtnClick(product.product.id)
                                  : () => {}
                          }
                      >
                          +
                      </button>
                  </div>
                  <div className="quotationModalItemInformation__quantity__remove">
                      <button
                          className="quotationModalItemInformation__quantity__remove__btn"
                          onClick={() =>
                              onRemoveBtnClick !== undefined
                                  ? onRemoveBtnClick(product.product.id)
                                  : () => {}
                          }
                      >
                          <img src={TrashIcon} alt="x" />
                          Hilangkan
                      </button>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default QuotationModalItem;
